<template>
    <nav>
        <md-tabs md-alignment="fixed" md-sync-route>
            <md-tab id="tab-gruppen" :md-label="lang.turnierplan.navElementGruppen" 
                :to="{ name: 'ListGruppen', params: { turnierID: turnierID }}" exact></md-tab>
            <md-tab id="tab-gruppen-spiele" :md-label="lang.turnierplan.navElementGruppenSpiele"
                :to="{ name: 'ListGruppenSpiele', params: { turnierID: turnierID }}"></md-tab>
            <md-tab id="tab-final-spiele" :md-label="lang.turnierplan.navElementTurnierbaum" 
                :to="{ name: 'ListFinalSpiele', params: { turnierID: turnierID }}"></md-tab>
        </md-tabs>
    </nav>
</template>

<script>
import store from '@/store/index'

export default {
    name: 'TurnierplanNavigationGruppenSystem',
    props: ['turnierID'],
    computed: {
        lang() {
            return store.getters.getLanguage;
        }
    }
}
</script>
