<template>
    <div class="app-container">
        <header>
            <router-link :to="{ name: 'TurnierDetail', params: { turnierID: turnierID }}">
                <button class="btn-unstyled btn-back"><i class="fas fa-chevron-left"></i></button>
            </router-link>
            <h2>{{lang.turnierplan.headline}}</h2>
        </header>
        <div v-if="turnierSpielModus == SPIELMODUS.GRUPPEN_SYSTEM.name">
            <TurnierplanNavigationGruppenSystem :turnierID="turnierID"/>
        </div>
        <div v-if="turnierSpielModus == SPIELMODUS.KO_SYSTEM.name">
            <TurnierplanNavigationKoSystem :turnierID="turnierID"/>
        </div>
        <div class="container">
            <transition appear name="fadeRight" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        <BottomBarDefault/>
        <md-progress-bar v-if="loadingStatus" md-mode="query"></md-progress-bar>
    </div>
</template>

<script>
import store from '@/store/index'
import TurnierplanNavigationGruppenSystem from '@/components/Utils/TurnierplanNavigationGruppenSystem.vue'
import TurnierplanNavigationKoSystem from '@/components/Utils/TurnierplanNavigationKoSystem.vue'
import BottomBarDefault from '@/components/Utils/BottomBarDefault.vue'

export default {
    name: 'Spiele',
    props: ['turnierID'],
    components: {
        TurnierplanNavigationGruppenSystem,
        TurnierplanNavigationKoSystem,
        BottomBarDefault
    },
    created() {
        store.commit("SET_DATA_LOADED_STATUS_SPIELE", false);
        store.dispatch('getSingleTurnier', this.turnierID);
        if (Object.keys(store.getters.getSpielerData) == 0) {
            store.dispatch('getSpielerData');
        }
        store.dispatch('getTeamsByTurnierID', this.turnierID);
        store.dispatch('getSpieleByTurnierID', this.turnierID);
    },
    data() {
        return {
            SPIELMODUS: store.getters.getSpielModus
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        },
        loadingStatus() {
            return store.state.spiele.loadingStatus;
        },
        turnierSpielModus() {
            return store.getters.getTurnier.spielmodus == undefined ? "": store.getters.getTurnier.spielmodus;
        }
    },
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/base';
    .container {
        margin-top: 60px;
    }
    header {
        box-shadow: none;
    }
    .app-container {
        padding: 50px 0 60px;
    }
    @media (min-width: $screen-sm) {
        .container {
            margin-top: 90px;
            width: 90%;
        }
        .app-container {
            padding: 50px 0 80px;
        }
    }
</style>